/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'M PLUS 2';
    src: url('../assets/MPLUS2-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
}

body {
    font-family: 'M PLUS 2', sans-serif;
    font-variation-settings: 'wght' 600;
    background: #F7F2F2;
}
